/*  Theme Name: Hiric | Responsive Bootstrap Landing Template Cyan
    Author: Themesbrand
    Version: 1.0.0
    File Description: Main CSS file of the template
*/


.text-custom,.read-btn,
.navbar-custom.navbar-white .navbar-nav li.active a,
.navbar-custom.navbar-white .navbar-nav li a:hover,
.navbar-custom.navbar-white .navbar-nav li a:focus,
.navbar-custom.navbar-white .navbar-nav li a:active,
.play-btn,.blog-title:hover,.social-icon:hover i{
    color: #1bbc9d !important;
}


.section-title-border, .bg-custom, .btn-custom,.features-item-list li:before,.process-left-icon-1 i,
.process-left-icon-2 i{
    background-color: #000000;
}



.btn-custom,.play-btn{
    border-color:#000000!important;
}

.btn-custom:hover,
.btn-custom:focus,
.btn-custom:active,
.btn-custom.active,
.btn-custom.focus,
.btn-custom:active,
.btn-custom:focus,
.btn-custom:hover,
.open > .dropdown-toggle.btn-custom{
	background-color: #0B2253;
}


.btn-custom:hover,
.btn-custom:focus,
.btn-custom:active,
.btn-custom.active,
.btn-custom.focus,
.btn-custom:active,
.btn-custom:focus,
.btn-custom:hover,
.open > .dropdown-toggle.btn-custom{
	border-color: #159078 !important;
}

.text-header{
    color: #000000 !important;
    font-size: 40px;
    font-weight: 600;
}

.text-inner{
    color: #041644 !important;
    font-size: 28px;
    font-weight: 600;
}
.text-center-black{
    color: #041644 !important;
    font-size: 40px;
    font-weight: 600;
}
.text-simple{
    color: #051A52 !important;
    font-size: 18px;
    font-weight: normal;
}
.text-simple-bold{
    color: #051A52 !important;
    font-size: 18px;
    font-weight: bold;
}
.navbar-brand{
    color: #041644 !important;
    font-weight: 800 !important;
    font-size: 24px;
}
.text-small{
    color: #051A52 !important;
    font-size: 18px;
    font-weight: normal;
    line-height: 32px;
}
.text-alone{
    color: #051A52 !important;
    font-size: 16px;
    font-weight: normal;
    line-height: 16px;
}

/* .group-photo{
    background: url('../../assets/images/group-photo.svg');
height: 100vh;
background-position: center;
background-repeat: no-repeat;
background-size: cover;
position: relative;

} */

.group-photo-container{
    position: absolute;               /* 2 */
    top: 50%;      
    left: 50%;
    transform: translate(-50%, -50%);
    text-align: center;
}

.make-a-booking{
    font-size: 16px;
    margin-bottom: 25px;

}



.app-download{
    /* background: url('../../assets/images/app-download.svg'); */
    background-color: #eea530;
    background-size: cover;
    /* background-repeat: no-repeat; */
    padding: 100px;
    border-radius: 36px;
    height: 374px;
    position: relative;
    align-items: center;

}

.phones{
    position: absolute;
    bottom: -100px;
    /* right: 7px; */
}

.memoButton{
    width: 199px;
    height: 55px;
    color: white;
    font-style: normal;
    font-weight: bold;
    font-size: 13px;
    line-height: 23px;
    align-items: center;
    text-align: center;
    background-color: #eea530;
    border: 0.5px solid #eea530;
    box-sizing: border-box;
    border-radius: 32px;
}

.gift-now {
    width: 100px;
    height: 45px;
    color: white;
    font-style: normal;
    font-weight: bold;
    font-size: 13px;
    line-height: 23px;
    align-items: center;
    text-align: center;
    background-color: #eea530;
    border: 0.5px solid #eea530;
    box-sizing: border-box;
    border-radius: 32px;
    text-decoration: none;
    display: flex;
    justify-content: center;
    
}

.gift-now:hover{
    color: #fff;
}

.app-download-anchor{
    width: 199px;
    height: 55px;
    /* color: white; */
    font-style: normal;
    font-weight: bold;
    font-size: 13px;
    line-height: 23px;
    align-items: center;
    text-align: center;
    background-color: #fff;
    border: 0.5px solid #fff;
    box-sizing: border-box;
    border-radius: 32px;
    text-decoration: none;

}


.major {
    position: relative
 }              

 .image-content {
    position: absolute;               /* 2 */
    top: 50%;                         /* 3 */
    transform: translate(-50%, -50%);
    width: 50px; 
}

.major-1 {
    position: relative
 }              


.memorable{ 
    color: #eea530;
}

.anchor-section2{
    color: #888888;
    margin-left: 100px;
    width: 199px;
    height: 55px;
    line-height: 23px;
    padding: 20px 32px;


}

.anchor-section1{
    color: #888888;
    width: 199px;
    height: 55px;
    line-height: 23px;
    padding: 20px 32px;


}

.anchor-section2:hover{
    width: 199px;
    height: 55px;
    color: #eea530;
    line-height: 23px;
    align-items: center;
    text-align: center;
    background-color: #fdf6ea;
    border: none;
    box-sizing: border-box;
    border-radius: 32px;
    padding: 20px 32px;
}

.anchor-section1:hover{
    width: 199px;
    height: 55px;
    color: #eea530;
    line-height: 23px;
    align-items: center;
    text-align: center;
    background-color: #fdf6ea;
    border: none;
    box-sizing: border-box;
    border-radius: 32px;
    padding: 20px 32px;
    
    

}




.woman-video{
    border-radius: 40px;
}

/* .first-section{ 
    background: url('../../assets/images/background.svg');
    background-size: contain;
} */

.fourth-section{ 
    background-color: #fffdfb;
    position: relative;
}


.third-section-container{
    border-radius: 32px;
    padding: 30px;
    box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
    position: absolute;
    top: -150px;
}

.parent {
    position: relative;
    top: 0;
    left: 0;
  }
  .image1 {
    position: relative;
    top: 0;
    left: 0;
  }
  .image2 {
    position: absolute;
    top: 30px;
    left: 30px;
    /* border: 1px green solid; */
  }

  .cake1{
    position:absolute;
      top: 25px;
      left: 25px;
      z-index: 8;
  }

  .features-box{
      position: relative;
  }

  .social-media{
      cursor: pointer;
  }

  .card-content-heading{
      font-size: 16px;
  }

  .card-content-address{
    font-size: 12px;
}

.popular-orders{
    font-weight:bold;
    font-style: normal;
}

.grouped-content{
    display: flex;
    justify-content: space-between;
    margin-bottom: 50px;
    align-items: center;
    margin-bottom: 30px;
}

.grouped-contents{
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.group-content{
    margin-bottom: 50px;
}

.signin-image{
    width: 600px;
    height: 480px;
}


.forgot-password-image {
    -webkit-transform:rotate(180deg);
    -moz-transform: rotate(180deg);
    -ms-transform: rotate(180deg);
    -o-transform: rotate(180deg);
    transform: rotate(180deg);
    width: 600px;
    height: 480px;
  }

.memor{
    color: #eea530;
}

.memor:hover{
    color: #eea530;
}

.radio-group{
    width: 100%;
    border: 1px solid #979694;
     height: 56px;
      border-radius: 4px;
       align-items: center;
}

  @media only screen and (max-width: 479px) {
      .image-content{
          width: 58px;
      }

      .app-download-anchor{
          height: 40px
      }

      .popular-orders{
          margin-top: 80px;
      }

      .app-download{
        height: 583px;
      }

      .app-heading{
        font-size: 24px;
      }


      .phones{
        position: absolute;
        top: 113px;
    }

    .card-content-address{
        margin-left: 30px;
    }

    .signin-image{
        display: none;
    }
      
  }

  

