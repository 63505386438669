body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.container{
  max-width: 1245px !important;
}
code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
label{
  color: #828282 !important;
}
p{
  color: #222222;
}
b{
  font-weight: bold !important;
}
#entries-filter{
  padding: 2px;
}
#custom-select{
  padding: 3px;
}
/* .carousel-container{
  width: 100% !important;
} */
/* .react-multi-carousel-item{
  max-width: 230px;
  min-width:230px;
} */
.carousel-item-padding-40-px{
  width: 100% !important;
}
.cursor-pointer{
  cursor: pointer;
}
.form-control:hover{
  border-color: rgba(0, 0, 0, 0.87) !important;
  border-style: solid !important;
  border-width: 1px !important;
  border-radius: 4px !important;
}
.form-control:focus{
  border-color: #eea5308a !important;
  border-style: solid !important;
  border-width: 2px !important;
  box-shadow: none !important;
}
.MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline{
  border-color: #eea5308a !important;
    border-style: solid !important;
    border-width: 2px !important;
    box-shadow: none;
}

/* firefox input fields fix */

.form-group{
  -webkit-flex: 1 1 auto m !important;
  flex: 1 1 auto !important;
}